import React, { useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAds, getAdsByType } from '../../redux/actions/BannerActions'



export const NCarousel = ({ slide1 }) => {
  const dispatch = useDispatch()
  const { adsNewFeatureLists } = useSelector((state) => state.adsList)
  useEffect(() => {
    dispatch(getAdsByType("newFeature"))

    return () => {

    }
  }, [])

  return (
    <div className="carousel-container">
    <Carousel variant='dark' >
        {adsNewFeatureLists.map(notice => (
          <Carousel.Item className='' >
              
              <img            
                className="img-carousel"
                src={notice.image.url.medium}
                alt=""
                
              />

              <Carousel.Caption className='New-container' >
                
                <div className='new-title'>{notice.name}</div>
                <div className='limited-text'>{notice.description}</div>
                <a  href={notice.link} target='blank' style={{padding:'4px', color:'white'}}>Ver más</a>
              </Carousel.Caption>

            </Carousel.Item>
          ))
        }


      </Carousel>
    </div>
  )
}
