// StepperProgress.jsx
import React from 'react';
import { Stepper, Step, StepLabel, StepContent, Typography, Button, Paper, imageListClasses } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import './progress_styles.css'; // Asegúrate de importar el archivo CSS
import style from '././../EcotiendaSteps/HowEcoTienda.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import Paso01 from '../../assets/img/icons/primerpaso.svg'
import Paso02 from '../../assets/img/icons/segundopaso.svg'
import Paso03 from '../../assets/img/icons/tercerpaso.svg'
import Paso04 from '../../assets/img/icons/cuartopaso.svg'


const steps = [
    {
        //label: 'Descarga la aplicacion movil  y Registrate en https://play.google.com/store/apps/details?id=com.ecoideas.app&hl=es_419 o accede a https://ecoideas.app',
        description: 'Descarga la app',
        image: Paso01,	
    
    },
    {
        //label: 'Para ser Ecotendero primero debes tener una cuenta verificada',
        description: 'Verifica tu cuenta',
        image: Paso02,	
    
    },
    {
        //label: 'Y tambien se parte del equipo Ecomaster',
        description: 'Solicita ser Ecomaster',
        image: Paso03,	
          
    },
    {
     //   label: '¡Ahora sí! ¡Felicitaciones! Ya puedes solicitar ser Ecotienda ',
        description: 'Solicita ser Ecotienda',
        image: Paso04,	
    
 },
];

const ProgressSteps = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(prevActiveStep => Math.min(prevActiveStep + 1, steps.length - 1));
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, 0));
    };

    return (
        <div className="stepper-progress">
        <Container className='progress-container-info'>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel>{step.description}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className="step-content-container">
                <Paper elevation={3} className="step-content">
                    <Typography variant="body1">{steps[activeStep].label}</Typography>
                    {steps[activeStep].image && (
                        <div className="step-image">
                            <img src={steps[activeStep].image} alt={`Paso ${activeStep + 1}`} />
                        </div>
                    )}
                </Paper>
                <div className="stepper-buttons">
                    <Button
                        variant="contained"
                        style={{ marginLeft: '8px' }}
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{ backgroundColor: '#F2A2C0', color: 'black', borderRadius: '20px' }}
                    >
                        Volver
                    </Button>
                    <Button
                        variant="contained"                     
                        onClick={handleNext}
                        style={{ marginLeft: '8px' }}
                        disabled={activeStep === steps.length - 1}
                        sx={{ backgroundColor: '#F2A2C0', color: 'black', borderRadius: '20px' }}
                    >
                        Siguiente
                    </Button>
                </div>
            </div>
            <div className={style.title}>
                <span className={style.titleH}>¡Sé </span> parte de Ecoideas y obtén grandes beneficios!
            </div>
            <div className={style.info_card_container}>
                <div className={style.info_card}>Podrás comprar productos de Ecoideas a un <strong>precio ESPECIAL</strong></div>
                <div className={style.info_card}><strong>Obtendrás ecopuntos</strong> por compras, canjeables por productos y premios</div>
            </div>
          </Container>
        </div>

    );
};

export default ProgressSteps;