import React from 'react';
import './styles.css';

const ShippingInfo = () => {
  return (
    <div className="shipping-info">
      <div className="shipping-info__item">
        <h3>Envíos Gratis</h3>
        <p>
        Los envíos son gratis a algunas ciudades de Colombia por compras superiores a $250.000. Aplican términos y condiciones, que deben ser consultadas a través del número de servicio al cliente.
        </p>
      </div>
      <div className="shipping-info__item">
        <h3>Costo de Envío</h3>
        <p>
        El flete debe ser pagado contra entrega y se calcula según el peso y volumen del envío.
        </p>
      </div>
    </div>
  );
};

export default ShippingInfo;